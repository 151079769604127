import revive_payload_client_LXPboolNlN from "D:/word/qcwp-vue/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.13.0_eslint@8.33.0_sass@1.58.0_stylelint@14.16.1_typescript@5.0.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uaafPGpRef from "D:/word/qcwp-vue/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.13.0_eslint@8.33.0_sass@1.58.0_stylelint@14.16.1_typescript@5.0.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nC2tQxn7u1 from "D:/word/qcwp-vue/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.13.0_eslint@8.33.0_sass@1.58.0_stylelint@14.16.1_typescript@5.0.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4fRQCCit7P from "D:/word/qcwp-vue/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.13.0_eslint@8.33.0_sass@1.58.0_stylelint@14.16.1_typescript@5.0.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_HPs1bajO0N from "D:/word/qcwp-vue/node_modules/.pnpm/@pinia+nuxt@0.4.6_typescript@5.0.4_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/word/qcwp-vue/packages/web/.nuxt/components.plugin.mjs";
import prefetch_client_0ObQ993znp from "D:/word/qcwp-vue/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.13.0_eslint@8.33.0_sass@1.58.0_stylelint@14.16.1_typescript@5.0.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_yt6iFESJS2 from "D:/word/qcwp-vue/node_modules/.pnpm/@nuxtjs+color-mode@3.2.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "D:/word/qcwp-vue/packages/web/.nuxt/unocss.mjs";
import plugin_oCxCdUWtJZ from "D:/word/qcwp-vue/node_modules/.pnpm/@nuxtjs+device@3.1.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_vbBVD0xQu1 from "D:/word/qcwp-vue/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.13.0_eslint@8.33.0_sass@1.58.0_stylelint@14.16.1_typescript@5.0.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_hbyuIVSNyZ from "D:/word/qcwp-vue/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.0.0_@pinia+nuxt@0.4.6_pinia@2.0.30/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _1_naive_ui_gDBuQl6Ccm from "D:/word/qcwp-vue/packages/web/src/plugins/1.naive-ui.ts";
import abort_4GEjbTgkOb from "D:/word/qcwp-vue/packages/web/src/plugins/abort.ts";
import directive_xcwu8jHEeN from "D:/word/qcwp-vue/packages/web/src/plugins/directive.ts";
import error_t9vrANEheK from "D:/word/qcwp-vue/packages/web/src/plugins/error.ts";
import images_ZPfaD2QGDe from "D:/word/qcwp-vue/packages/web/src/plugins/images.ts";
import mitt_tyTIsSgT7h from "D:/word/qcwp-vue/packages/web/src/plugins/mitt.ts";
import old_6c8Iqda5RU from "D:/word/qcwp-vue/packages/web/src/plugins/old.ts";
import pinia_cfuRVwzvtd from "D:/word/qcwp-vue/packages/web/src/plugins/pinia.ts";
import runtime_hooks_ryc2AVBBPO from "D:/word/qcwp-vue/packages/web/src/plugins/runtime-hooks.ts";
import vant_a3hGDiLsu0 from "D:/word/qcwp-vue/packages/web/src/plugins/vant.ts";
import vconsole_client_bVGfAYsPrU from "D:/word/qcwp-vue/packages/web/src/plugins/vconsole.client.ts";
export default [
  revive_payload_client_LXPboolNlN,
  unhead_uaafPGpRef,
  router_nC2tQxn7u1,
  payload_client_4fRQCCit7P,
  plugin_vue3_HPs1bajO0N,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0ObQ993znp,
  plugin_client_yt6iFESJS2,
  unocss_MzCDxu9LMj,
  plugin_oCxCdUWtJZ,
  chunk_reload_client_vbBVD0xQu1,
  plugin_hbyuIVSNyZ,
  _1_naive_ui_gDBuQl6Ccm,
  abort_4GEjbTgkOb,
  directive_xcwu8jHEeN,
  error_t9vrANEheK,
  images_ZPfaD2QGDe,
  mitt_tyTIsSgT7h,
  old_6c8Iqda5RU,
  pinia_cfuRVwzvtd,
  runtime_hooks_ryc2AVBBPO,
  vant_a3hGDiLsu0,
  vconsole_client_bVGfAYsPrU
]